.data-table {
   z-index: 1 !important;
}

 

 .data-table .p-paginator .p-paginator-page:hover {
   background-color: #84b5db; 
   color: #0c2235; 
 }
 
 .data-table .p-paginator .p-paginator-prev,
 .data-table .p-paginator .p-paginator-next {
   color: #499ed4; 
 }
 
.data-table .p-datatable-tbody > tr > td {
  padding: 6px;
}


.select-branch {
  z-index: 2 !important;
}


.p-sortable-column-icon {
  color: white !important;
  font-size: 12px !important
}

